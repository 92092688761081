<template>
  <v-container
    fluid
    style="height:100vh;padding:0;display:flex;align-items:center;justify-content:center;background:#d0d0ce;"
    id="loginMainContainer"
  >
    <div class="myForm" style="max-width:400px;width:95%">
      <div style="margin-top:10px;margin-bottom:40px;width:100%;text-align:center;">
        <img src="@/assets/logo.png" width="50px" style="margin:0 auto" />
        <div style="margin-top:-8px;">
          <span style="font-size:12px;font-family: Noto Sans TC, sans-serif;font-weight:500;">EppBuyer</span>
        </div>
        <div>
          <span style="font-size:106px;">404</span>
        </div>
        <div>
          <span>Oops! That page can't be found.</span>
        </div>
        <div>
          <span style="font-size:27px;">您請求的頁面不存在</span>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped></style>
